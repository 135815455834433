import styled from '@emotion/styled'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

const FlexItemContainer = styled.div<{ col?: number; gap: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${({ gap }) => `${gap / 2}px`};
  align-items: flex-start;
`
const FlexItemContainerItem = styled.div<{ col: number; gap: number }>`
  flex: 1 0 ${({ col }) => `${100 / col - 1}%`};
  padding: ${({ gap }) => `${gap / 2}px`};
`

const FmePaddingContainer = styled.div<{
  left?: number
  right?: number
  top?: number
  bottom?: number
  className?: string
}>`
  padding: ${({ top, left, right, bottom }) =>
    `${top || 0}px ${right || 0}px ${bottom || 0}px ${left || 0}px`};
`

type FmeContainerProps = HTMLAttributes<HTMLDivElement> & PropsWithChildren
const FmeContainer = ({ children, ...props }: FmeContainerProps) => (
  <FmePaddingContainer {...props} left={16} right={16} bottom={16} top={16}>
    {children}
  </FmePaddingContainer>
)

type FmeFlexItemContainerProps = {
  itemKey: string
  col?: number
  gap?: number
  items: any[]
} & WithChildren
const FmeFlexItemContainer = ({ col = 1, itemKey, gap = 0, items }: FmeFlexItemContainerProps) => {
  return (
    <FlexItemContainer gap={gap}>
      {items.map((v, i) => {
        return (
          <FlexItemContainerItem gap={gap} col={col} key={`${itemKey}-${i}`}>
            {v}
          </FlexItemContainerItem>
        )
      })}
    </FlexItemContainer>
  )
}

export { FmeContainer, FmePaddingContainer, FmeFlexItemContainer }
