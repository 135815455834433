import moment from 'moment/moment'

import getConfig from 'next/config'
import { GetServerSidePropsContext } from 'next'
import dayjs from 'dayjs'
import { parseCookies } from 'nookies'
import axios, { AxiosError } from 'axios'
import sanitizeHtml from 'sanitize-html'
import { Mutex } from 'async-mutex'
import { get } from '@/pages/api/base'
import { APP_TYPE_EXTERNAL } from '@/constants/entity'

const creatorsList: {
  [id: string]: string
} = JSON.parse(JSON.stringify(process.env.REDIRECT_CREATORS_URL))

export const shortenText = (str: string, len = 140, suffix = '...') => {
  const sanitizedHtml = sanitizeHtml(str, {
    allowedTags: ['br'],
  })

  if (str.length <= len) return sanitizedHtml

  return `${sanitizedHtml.substring(0, len)}${suffix}`
}

export const updateInputValue = (handler: (a: any) => any) => (key: string) => (e: any) => {
  handler((v: any) => {
    v[key] = e.target.value
    return { ...v }
  })
}

export const isKatakana = (str: string) => {
  return /^[\u30A0-\u30FF\s\u3000]+$/.test(str)
}

/**
 * validate account identity is not taken
 * @param account
 * @param baseURL
 * @returns
 */
export const validAccountIdentityUsed = async (account: string) => {
  try {
    const data = await get(`/creators/self/@${account}/taken`, null, { auth: true })
    if (data === 200) return true
  } catch (e) {
    return false
  }
  return false
}

export const formatDate = (date: any, tzOffset = 0) => {
  if (date) {
    return moment(date).add(tzOffset, 'hour').format('YYYY.MM.DD HH:mm')
  }
  return ''
}

export const formatDateJP = (date: any, tzOffset = 0) => {
  if (date) {
    return moment(date).add(tzOffset, 'hour').format('YYYY年MM月DD日 HH:mm')
  }
  return ''
}

export const gettingDataForMiniApp = async (
  ctx: GetServerSidePropsContext,
  from = '',
): Promise<any> => {
  const cookies = parseCookies({ req: ctx.req })
  const token = cookies[process.env.NEXT_PUBLIC_TOKEN_KEY as string]

  const { publicRuntimeConfig } = getConfig()

  const creatorId = String(ctx.query.creator_id)
  const appsKey = String(ctx.query.apps_key)
  const targetId = Object.keys(creatorsList).filter(id => creatorId === id)

  /**
   * metaタグ生成用にサーバーサイドで取得する
   */
  const creatorMiniAppDataUrl = `${publicRuntimeConfig.API_BASE_URL}/creators/${creatorId}/mini_app`
  const sellerInfoDataUrl =
    from === 'viewer'
      ? `${publicRuntimeConfig.API_BASE_URL}/creators/${creatorId}?from=miniapp`
      : `${publicRuntimeConfig.API_BASE_URL}/creators/${creatorId}`

  try {
    const requests = [
      axios.get(creatorMiniAppDataUrl, { headers: { Authorization: `Bearer ${token}` } }),
      axios.get(sellerInfoDataUrl),
    ]

    const [creatorMiniAppDataResponse, sellerInfoDataResponse] = await Promise.all(requests)

    const creatorMiniAppData = creatorMiniAppDataResponse.data
    const sellerInfoData = sellerInfoDataResponse.data

    const appInfoDataUrl =
      creatorMiniAppData && appsKey && typeof appsKey === 'string'
        ? isNaN(Number(appsKey))
          ? `${publicRuntimeConfig.API_URL}/api/app`
          : `${publicRuntimeConfig.API_URL}/api/app/old_url`
        : ''

    const appInfoParams =
      creatorMiniAppData && appsKey && typeof appsKey === 'string'
        ? isNaN(Number(appsKey))
          ? {
              app_name: appsKey!.toString().substring(0, 3),
              seller_app_key: appsKey!.toString().substring(3),
              creator_uid: sellerInfoData.uid,
            }
          : {
              seller_app_id: appsKey!.toString(),
            }
        : null
    const { data: appInfoData } = await axios.post(appInfoDataUrl, appInfoParams)

    if (appInfoData?.type && appInfoData?.type === APP_TYPE_EXTERNAL) {
      // 外部のURLはリダイレクト
      window.location.href = appInfoData.url
      return
    }

    return {
      props: {
        appInfoData,
        creatorMiniAppData,
        creatorId,
        sellerInfoData,
        publicRuntimeConfig,
        buildTime: dayjs().unix(),
      },
      targetId,
    }
  } catch (e) {
    // TODO Error handling
    if (e instanceof AxiosError) {
      if (e.response?.data) {
        console.error(e.response)
      } else {
        console.error(e)
      }
    } else {
      console.error(e)
    }
    return {
      notFound: true,
    }
  }
}

let _snackPublicKey: number
let _snackModulo: number
let _snackError = false
const _snackMutex = new Mutex()

export const generateSnackImageUrl = async (
  options: string,
  imageUrl: string,
  isOgp = false,
  ogpName = '',
) => {
  const snackServerUrl = 'https://img.plt.ttkpf.com'

  const getSnackPublicKey = async () => {
    try {
      const client = axios.create()
      const res = await client.get(`${snackServerUrl}/pubkey`)
      _snackPublicKey = res.data['publicKey']
      _snackModulo = res.data['modulo']
    } catch (err) {
      _snackError = true
      return
    }
  }

  const encryptSnackString = (str: string) => {
    if (_snackModulo === undefined || _snackPublicKey === undefined) {
      return str
    }
    const encoder = new TextEncoder()
    const utf8 = encoder.encode(str)

    const encrypted = utf8.map(v => {
      return (v + _snackPublicKey!) % _snackModulo!
    })

    const encoded = btoa(String.fromCharCode(...encrypted))
    //return Buffer.from(encrypted).toString("base64");

    return encodeURIComponent(encoded)
  }

  if ((_snackModulo === undefined || _snackPublicKey === undefined) && !_snackError) {
    const release = await _snackMutex.acquire()

    // ロック獲得待ち中に、前回処理でデータが取得できている or エラーの場合は、再度取得しない
    if ((_snackModulo === undefined || _snackPublicKey === undefined) && !_snackError) {
      await getSnackPublicKey()
    }

    release()
  }
  if (_snackModulo === undefined || _snackPublicKey === undefined) {
    return imageUrl
  }

  if (isOgp) {
    const encName = encryptSnackString(ogpName)
    const encUrl = encryptSnackString(imageUrl)
    return `${snackServerUrl}/fanme-ogp/${encName}?u=${encUrl}`
  } else {
    const encOptions = encryptSnackString(options)
    const encUrl = encryptSnackString(imageUrl)
    return `${snackServerUrl}/${encOptions}?u=${encUrl}`
  }
}

// リンクまとめの画像の更新は画像URLが変わらないため、変更した直後ローカルキャッシュが使われてしまい画像が更新されないという問題がある
// そのため、画像が変更された直後は画像が更新されるように変更直後の日時をパラメータに追加し、キャッシュを使わずに画像を取得されるようにする（キャッシュバスター）
// ただし、毎アクセスで日時を更新してしまうと画像の表示のたびにサーバーにアクセスすることになるため、更新頻度を抑えるために、
// 最後に更新した日時をローカルストレージに保存し、パラメータはその日時を使うようにする
// また、アップロードした直後はエッジコンピューティングのためか、同じURLにアクセスしても画像が更新されないことがあるため、数回リトライ処理を入れておく
// メモ: 基本的に、この処理はあまり使わないほうが良く、画像の更新は画像URLを変更するようにしたほうが良い
export const appendDateParameterToUrl = (imageUrl: string): string => {
  if (!imageUrl) return imageUrl
  const lastUpdateContentImage = getLastUpdateLinkContentTime()
  if (!lastUpdateContentImage) return imageUrl
  const separator = imageUrl.includes('?') ? '&' : '?'
  return `${imageUrl}${separator}d=${lastUpdateContentImage}`
}
export const getLastUpdateLinkContentTime = (): string | null => {
  return localStorage.getItem('lastUpdateLinkContentTime')
}
export const setLastUpdateLinkContentTime = () => {
  localStorage.setItem('lastUpdateLinkContentTime', String(Date.now()))
}
export const updateContentWithRetry = (updateContent: () => void) => {
  // 0, 3, 6 秒でリトライする
  for (let i = 0; i <= 6; i += 3) {
    setTimeout(() => {
      setLastUpdateLinkContentTime()
      updateContent()
    }, i * 1000)
  }
}
