import React, { ReactElement, useState } from 'react'
import { Spacer } from '@nextui-org/react'
import { FmeButton } from '@/fanmeUi/elements/button'

type UsePaginatedStreamProps = {
  builder: (items: any, vendors: any, index: number) => any
  useFetcher: (token?: string | null) => any
  initToken?: string | null
  numPerGroup?: number
  loadMoreButtonLabel?: string
  loadPrevButtonLabel?: string
  callback?: (token: string | undefined | null, data: any) => any
  loaderBuilder?: () => ReactElement
}
type PageSectionProps = {
  useFetcher: (token?: string | null) => any
  builder: (items: any, vendors: any, index: number) => any
  onNext: (i: string) => void
  onPrev: (i: string) => void
  token?: string | null
  isTail: boolean
  index: number
  isHead: boolean
  numPerGroup?: number
  loadMoreButtonLabel?: string
  loadPrevButtonLabel?: string
  callback?: (token: string | undefined | null, data: any) => any
  loaderBuilder?: () => ReactElement
}
const PageSection = ({
  token,
  useFetcher,
  builder,
  loadMoreButtonLabel,
  loadPrevButtonLabel,
  callback,
  isTail,
  index,
  isHead,
  onNext,
  onPrev,
  loaderBuilder,
}: PageSectionProps) => {
  const { data } = useFetcher(token)
  const items = data?.items

  let vendors = {}

  if (callback) {
    vendors = callback(token, data)
  }

  if (isHead && !items) return loaderBuilder ? loaderBuilder() : null

  if (!items) {
    return null
  }

  return (
    <>
      {isHead && data.prev_token && (
        <>
          <FmeButton
            buttonSize={'small'}
            buttonStyle={'black-outline'}
            onClick={async () => {
              onPrev(data.prev_token)
            }}
          >
            {loadPrevButtonLabel || '前のページを読み込む'}
          </FmeButton>
          <Spacer y={1} />
        </>
      )}
      {builder(items, vendors, index)}
      {isTail && data.next_token && (
        <>
          <Spacer y={1} />
          <FmeButton
            buttonSize={'small'}
            buttonStyle={'black-outline'}
            onClick={async () => {
              onNext(data.next_token)
            }}
          >
            {loadMoreButtonLabel || 'もっと見る'}
          </FmeButton>
        </>
      )}
    </>
  )
}
const usePaginatedStream = ({
  builder,
  useFetcher,
  callback,
  numPerGroup = 1,
  initToken,
  loadPrevButtonLabel,
  loadMoreButtonLabel,
  loaderBuilder,
}: UsePaginatedStreamProps) => {
  const [tokens, setTokens] = useState<(string | null | undefined)[]>([initToken])
  const [ts, setTs] = useState<string>(Date.now().toString())

  return {
    tokens: tokens,
    refresh: () => {
      setTs(Date.now().toString())
    },
    loadNext: () => {},
    component: (
      <>
        {tokens.map((t, i: number) => {
          return (
            <PageSection
              builder={builder}
              useFetcher={useFetcher}
              callback={callback}
              loadPrevButtonLabel={loadPrevButtonLabel}
              loadMoreButtonLabel={loadMoreButtonLabel}
              isHead={i === 0}
              isTail={i === tokens.length - 1}
              index={i}
              token={t}
              onPrev={t => {
                setTokens(tokens => {
                  return [t, ...tokens]
                })
              }}
              onNext={t => {
                setTokens(tokens => {
                  return [...tokens, t]
                })
              }}
              key={`page-${ts}-${i}-${t || '_'}`}
              loaderBuilder={loaderBuilder}
            />
          )
        })}
      </>
    ),
  }
}

export { usePaginatedStream }
